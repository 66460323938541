.Container {
  color: black;
  padding: 4%;
}

.Title {
  font-weight: bolder;
  font-size: 1.4em;
  margin-bottom: 2%;
}

.MenuWrap {
  height: 100px;
}

.Dropdown,
.Dropdown:hover,
.Dropdown:active {
  background-color: gray !important;
  border: none !important;
  height: 40%;
  margin-top: -0.5%;
}

.Search {
  margin-left: 3%;
  height: 40%;
  width: 40%;
}

.SearchBtn {
  margin-left: 3%;
  height: 40%;
  width: 10%;
  margin-top: -0.5%;
}

.CreateBtn {
  margin-left: 3%;
  height: 40%;
  margin-top: -0.5%;
}

.manage-container {
  text-align: center;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
}

.grid-design {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1.75fr 2.5fr 10.25fr 2.5fr 2fr 2.5fr 2.5fr;
}

.grid-design * {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.table-header {
  width: 100%;
  font-weight: 700;
  background-color: #dee2e6;
  height: 2.5rem;
}

.table-list {
  padding: 0.75rem 0rem;
  border-bottom: 1px solid #ccc;
}

.table-list a {
  color: #000;
}

.answer-list-container {
  padding: 0;
}

.paging{
  width:100px;
}

@media (max-width: 750px) {
  .table-list {
    font-size: 0.75rem;
  }
  .table-header {
    font-size: 0.8rem;
  }
}

@media (max-width: 550px) {
  .table-list {
    font-size: 0.75rem;
  }
  .table-header {
    font-size: 0.6rem;
  }
}
