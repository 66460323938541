.Map{
    background : rgba(0,0,0,0.25);
    position : fixed;
    left:50%;
    top:0;
    height:100%;
    width:50vh !important;
}

.ContainerPc{
    background-color: #3D5398;
    color: white;
    line-height: 30px;
    margin: auto;
    width: 28rem;
    height: 100vh;
    padding: 3%;
}

.Container{
    background-color: #3D5398;
    color: white;
    line-height: 30px;
    margin: auto;
    width: 100vw;
    height: 100vh;
    padding: 3%;
}

.Title{
    font-size: 2em;
    text-align: center;
    padding: 20% 0;
}

.EmailAndPasswordInput{
    margin: 0 auto;
    width: 100%;
    font-size: 1em;
}

.Email{
    height: 45px;
    width: 100%;
    border-radius: 46px;
    margin-bottom: 6%;
    padding-left: 5%;
}

.Password{
    height: 45px;
    width: 100%;
    border-radius: 46px;
    padding-left: 5%;
}

.Group{
    font-size: 1em;

}

.GroupText{
    height: 45px;
    width: 75%;
    margin-right: 2%;
    border-radius: 46px;
    padding-left: 5%;
}

.SearchBtn{
    border-radius: 46px;
    width: 20%;
    height: 45px;
    background-color: black;
    color: white;
    float: right;
}

.SignUpBtnWrap{
   text-align: center;
   margin-top: 20%;
}

.SignUpBtn{
    width: 50%;
    height: 40px;
    background-color: #FFFFFF !important;
    color: #3D5398 !important;
    border: #FFFFFF solid !important;
}

.CancleBtn, .CancleBtn:hover{
    background-color: #D9D9D9;
    border: #D9D9D9 1px !important;
    color: black;
    border-radius: 4px !important;
    margin-right: 2%;
    width: 20%;
    height: 40px;
}

.CheckBtn, .CheckBtn:hover{
    background-color: #3D5398;
    border: #3D5398 1px !important;
    color: white;
    border-radius: 4px !important;
    width: 20%;
    height: 40px;
}

.BoldText{
    min-width: 40px;
    max-width: 40px;
    font-weight: bolder;
    text-align: left;
}

.TextContent{
    min-width: 120px;
    max-width: 120px;
    text-align: right;
}