.popup-container {
  display: block;
  position: absolute;
}

.popup-input {
  border-radius: 1.25rem;
}

.button-container {
  display: flex;
}
.button-container button {
  margin-left: 0.5rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  border-radius: 1rem;
}