.Container{
    padding: 3%;
}

.MenuWrap{
    margin-top: 2%;
}

.Search{
    margin-left: 3%;
    margin-right: 2%;
}

.TaskBtn{
    float: right;
    margin-right: 2%;
}

.AddMemberBtn{
    float:right;
}

.TaskToggleBtn, .TaskToggleBtn:hover{
    font-size: 0.5vw !important;
    height: 30px;
    margin-right: 0.3%;
    background-color: #E6E6E6 !important;
    border: #E6E6E6 !important;
    color: black !important;
}

.TaskToggleBtnOn{
    font-size: 0.5vw !important;
    height: 30px;
    margin-right: 0.3%;
    background-color: #A2BA00 !important;
    border: #A2BA00 !important;
    color: black !important;
}

.TableHead{
    top: 0;
    text-align: center;
    position: sticky;
    z-index: 999;
    background-color: #FFFFFF;
}

.TaskBox{
    margin: 1.5%;
    max-height: 300px !important;
    min-height: 200px;
    overflow-y: scroll;
}

.Allcheck{
    vertical-align: super;
    margin-left: 5%;
    font-size: 1vw;
}

.AllcheckWrap{
    margin-left: 7%;
}

.Header{
    text-align: center;
    height: 40px;
    z-index: 9999;
}

.SearchBox{
    display: inline-block;
    margin-right: 2%;
    width: 50%;
    vertical-align: middle;
}


.SearchInput{
    min-height: 30px;
    vertical-align:bottom;
    width: 100%;
}

.SearchResult{
    margin-right: 2%;
    font-size: larger;
}

.QuizTable{
 z-index: -1;
}

 td {
    border-bottom: 1px solid #E6E6E6 !important;
    padding: 10px !important;
    text-align: center;
  }

.Dropdown,
.Dropdown:hover,
.Dropdown:active {
  border: none !important;
  font-size: 1.5vw !important;
}
