.Container{
    padding: 3%;
}

.MenuWrap{
    margin-top: 2%;
}

.Search{
    margin-left: 3%;
    margin-right: 2%;
}

.AddMemberBtn{
    float:right;
}