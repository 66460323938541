.canvas-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.canvas-container,
.canvas {
  background-color: #AAAAAA;
}

.edit-theory {
  width: 70vw;
  height: 90vh;
}