.default {
  font-size: 1.15rem;
}

.theory {
  padding-left: 1rem;
}
.selected {
  color: blueviolet;
  text-decoration: underline;
}

.edit {
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
