.round-text {
  font-weight: bold;
  font-size: 0.875rem;
  position: absolute;
  vertical-align: middle;
  right: 5%;
  margin-right: 1%;
}

.question-number {
  font-weight: bold;
  font-size: 0.875rem;
  margin-top: 14%;
  position: absolute;
  vertical-align: middle;
}

.round-image {
  max-height: 90px;
  min-height: 90px;
  max-width: 70px;
  min-width: 70px;
  cursor: pointer;
  position: relative;
}

@media all and (min-width: 1024px) {
  .round-text {
    margin-top: 9%;
    font-size: 0.8rem;
  }

  .question-number {
    margin-top: 9%;
    font-size: 0.8rem;
  }
}

/* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width: 768px) and (max-width: 1023px) {
  .round-text {
    margin-top: 10%;
  }

  .question-number {
    margin-top: 10%;
  }
}

/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (min-width: 480px) and (max-width: 767px) {
  .round-text {
    margin-top: 15%;
  }

  .question-number {
    margin-top: 15%;
  }
}

/* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (max-width: 479px) {
  .round-text {
    margin-top: 15%;
  }

  .question-number {
    margin-top: 15%;
  }
}
