.HeaderWrap{
    padding: 1%;
    position: sticky !important;
    z-index: 999 !important;
    top: 0;
    border-bottom: 2px solid;
    width: 100%;
}

.ListTitle, .ListTitle:hover{
    font-size: 1.2em !important;
    color: black !important;
    cursor: pointer;
    margin-left: 0;
}


.List, .List:hover{
    font-size: 1em !important;
    color: black !important;
    vertical-align: bottom;
    cursor: pointer;
    width: 100%;
    margin-top: 1%;
    vertical-align: middle;
    margin-right: 15%;
}

.List:last-child, .List:last-child{
    font-size: 1em !important;
    color: black !important;
    vertical-align: bottom;
    cursor: pointer;
    margin-top: 1%;
    vertical-align: middle;
}

.List2, .List2:hover{
    font-size: 1em !important;
    color: black !important;
    vertical-align: bottom;
    cursor: pointer;
    margin-top: 1%;
    vertical-align: middle;

}
