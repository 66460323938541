* {
  font-size: 0.9em;
}

.TestWrap {
  width: 85%;
  margin: 2.5% auto;
  margin-top: 5%;
}
.Test {
  border: 1px solid white;
  height: 500px;
}

.TestStepWrap {
  display: flex;
}

.TestStep {
  display: inline-block;
  flex: 1;
  height: 3em;
  text-align: center;
  line-height: 50px;
  border-right: 2px solid white;
  background-color: #E9E9E9;
  color: #878787;
}

.TestStepNow {
  display: inline-block;
  flex: 1;
  height: 3em;
  text-align: center;
  line-height: 50px;
  border-right: 2px solid white;
  background-color: #4569F0;
  color: white;
}

.TestStep:last-child {
  border-right: none;
}

.TextWrap {
  text-align: center;
  margin-top: 6%;
  margin-bottom: 3%;
  color: black;
}

.Text {
  line-height: 7px;
  margin-top: 7%;
  margin-bottom: 6%;
  color: #2D3748;
}

.ExamWrap {
  height: 170px;
}

.Exam {
  margin-left: 15%;
}

.StartBtn {
  background-color: #4569F0;
  color: white;
  width: 140px;
  height: 40px;
  display: inline-block;
  padding: 5px 15px 5px 15px;
  line-height: 20px;
  border-radius: 6px;
  cursor: pointer;
  border: #4569F0;
}

.StartBtn:first-child{
  margin-right: 2%;
}

.mainBody {
  background-color: white;
  color: black;
  margin: 10px auto;
  text-align: center;
  width: 400px;
  height: 100px;
  border-radius: 20px;
  border: 1px solid #878787;
}
