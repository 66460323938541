*{
    font-size: 0.9em;
    margin: 0 auto;
}
.TestWrap{
    width:100%;
    margin: 0 auto;
    margin-top: 0%;
    height: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    justify-content: space-between;
}

.TestStepWrap{
    background-color: #cdcdcd;
    display: flex;
}

.TestStep {
    display: inline-block;
    flex: 1;
    height: 3em;
    text-align: center;
    line-height: 50px;
    border-right: 2px solid white;
    background-color: #E9E9E9;
    color: #878787;
}

.TestStepNow {
    display: inline-block;
    flex: 1;
    height: 3em;
    text-align: center;
    line-height: 50px;
    border-right: 2px solid white;
    background-color: #4569F0;
    color: white;
}

.TestStep:last-child{
    border-right: none;
}

.TextWrap{
    text-align: center;
    margin-bottom: 2%;
    margin-top: 1%;
}

.QuestionNum{
    display: inline-block;
    text-align: left;
    width: 95%;
}

.Timer {
    display: inline-block;
    color: #F24E1E;
    float: right;
}

.NextBtnOn{
    background-color: #4569F0;
    color: white;
    width: 160px;
    height: 45px;
    display: inline-block;
    padding: 5px 15px 5px 15px;
    line-height: 20px;
    border-radius: 4px;
    margin-top: 7%;
    border: #4569F0;
    cursor: pointer;
    font-size: large;
}

.NextBtnOff{
    color: #999999;
    width: 160px;
    height: 45px;
    display: inline-block;
    padding: 5px 15px 5px 15px;
    line-height: 20px;
    border-radius: 4px;
    margin-top: 7%;
    border: #878787 1px solid;
    cursor: default;
    font-size: large;
}


.AnswerWrap{
    width: 100%;
    margin: 0 auto;
    padding: 0 5% 0 5%;
    text-align: left;
}

.InputWrap{
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

.AnswerTitle{
    margin-bottom: 1%;
    text-align: center;
    display: flex;
    justify-content: space-between !important;
}

.Answer {
    width: 75%;
    height: 35px;
    margin-bottom: 3%;
    margin-top: 1%;
    border: 1px solid #9D9D9D;
}

.AddBtn{
    background-color: white;
    margin-left: 12.5%;
    width:30%;
    height: 35px;
    display: inline-block;
    padding: 5px 15px 5px 15px;
    line-height: 20px;
    border-radius: 15px;
    border: 2px solid #D9D9D9;
    cursor: pointer;
    text-align: start;
}
.TextLength{
    margin-left: 2%;
    color: #999999;
}

.PlusIcon{
    display: inline-block;
    width: 20px;
    height: 28px;
    margin-right: 5%;
    margin-top: -4%;
}

.AddText{
    vertical-align: top;
    display: inline-block;
}

.QuestionWrap{
    padding: 0 3% 0 3%;
    color: black;
}

.Content{
    border: 2px solid #D9D9D9;
    text-align: center;
    margin: 3% 0 3% 0;
}

.SmallText{
    margin-top: 0;
    font-size: 0.7em;
    line-height: 15px;
    color: #7E7E7E;
    margin-bottom: 1.5%;
}

.RemoveBtn{
    margin-left: 2%;
    height: 26px;
    vertical-align: middle;
    margin-top: -0.5%;
}

.Arrow, .Arrow:hover{
    background-color: white !important;
    color: black !important;
    border: 0 !important;
}

.ArrowNone{
    visibility: hidden;
    background-color: white !important;
    color: black !important;
    border: 0 !important;
}

.Box{
    margin-top: 2.5%;
    max-height: 60%;
    min-height: 20%;
    background-color: #7E7E7E;
    flex-direction: column;
    position : fixed;
    bottom : 0;
    width: 100%;

}

.QuestionIcon{
    position: relative;
    display: block;
    width: 100%;
    height: 15px;
    color: #878787;
}