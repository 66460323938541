.App {
  background-color: #3D5398;
  color: white;
  line-height: 30px;
  margin: auto;
  width: 100vw;
  height: 100vh;
}

.AppPc {
  background-color: #3D5398;
  color: white;
  line-height: 30px;
  margin: auto;
  width: 28rem;
  height: 100vh;
}

.LogoWrap{
  text-align: center;
  padding-top: 17vh;
}

.Title {
  font-size: 1.5em;
  font-weight: bold;
}

.EmailForm{
  text-align: center;
  margin-top: 5vh;
  padding-bottom: 3vh;
  padding-left: 1%;
}

.Email {
  margin-top: 1%;
  font-size: 1.3em;
  width: 90vw;
  height: 5vh;
  border-radius: 20px;
  border: 1px solid #AEAEAE;
  color: #AEAEAE;
  padding: 2%;
}

.EmailPc {
  margin-top: 1%;
  font-size: 1.3em;
  width: 90%;
  height: 5vh;
  border-radius: 20px;
  border: 1px solid #AEAEAE;
  color: #AEAEAE;
  padding: 2%;
}

.Login {
  list-style: none;
  font-size: 1.2em;
  border-radius: 6px;
  font-weight: bold;
  text-align: center;
  margin-top: 5vh;
}

.LoginBtn, .LoginBtn:hover{
  border: #FFFFFF !important;
  color: #3D5398 !important;
  background-color: #FFFFFF!important;
  font-weight: bold;
  margin-left: 4%;
  height: 6vh;
  width: 35vw;
}

.LoginBtnPc, .LoginBtnPc:hover{
  border: #FFFFFF !important;
  color: #3D5398 !important;
  background-color: #FFFFFF!important;
  font-weight: bold;
  margin-left: 4%;
  height: 6vh;
  width: 10vw;
}

.SignOnBtn, .SignOnBtn:hover{
  border: #FFFFFF !important;
  color: #3D5398 !important;
  background-color: #FFFFFF!important;
  font-weight: bold;
  height: 6vh;
  width: 35vw;
}

.SignOnBtnPc, .SignOnBtnPc:hover{
  border: #FFFFFF !important;
  color: #3D5398 !important;
  background-color: #FFFFFF!important;
  font-weight: bold;
  height: 6vh;
  width: 10vw;

}

.FalseEmailContainer{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5%;
}

.FalseEmail{
  font-size: 1em;
  color: white;
  background-color: black;
  border: black solid 1px;
  border-radius: 8px;
  height: 30px;
  position: relative;
  width: 60%;
  z-index: 100;
  text-align: center;
}
.FalseEmail::after{
  border-color: black transparent;
  border-style: solid;
  border-width: 8px 6px 0 6.5px;
  content: '';
  display: block;
  left: 50%;
  position: absolute;
  top:28px;
  width: 0;
  z-index: 1;
}
.FalseEmail::before{
  border-color: black transparent;

  border-style: solid;
  border-width: 8px 6px 0 6.5px;
  content: '';
  display: block;
  left: 50%;
  position: absolute;
  top: 28px;
  width: 0;
  z-index: 0;
}
.FalseEmailNone{
  font-size: 1em;
  visibility: hidden;
}


.ChangePassword {
  background-color: #3D5398;
  text-align: center;
  color: white;
  line-height: 30px;
  border-radius: 4px;
  width: 100vw;
  height: 100vh;

}

.ChangePasswordPc {
  text-align: center;
  background-color: #3D5398;
  color: white;
  line-height: 30px;
  border-radius: 4px;
  width: 28rem;
  height: 100vh;
}

.ChangePasswordBtn, .ChangePasswordBtn:hover{
  border: #FFFFFF !important;
  color: #3D5398 !important;
  background-color: #FFFFFF!important;
  font-weight: bold;
  height: 7.5vh;
  width: 50vw;
}

.ChangePasswordBtnPc, .ChangePasswordBtnPc:hover{
  border: #FFFFFF !important;
  color: #3D5398 !important;
  background-color: #FFFFFF!important;
  font-weight: bold;
  height: 8vh;
  width: 10vw;
}

.ChangePasswordInfoText{
  font-size: 1em;
  margin-top: 6vh;
  line-height: 24px;
}

.ResultBox{
  height: 120px;
  overflow-y: scroll;
  margin-bottom: -15%;
  background-color: #FFFFFF;
  color: black;
  width: 80%;
  margin-left: 0;
  border-radius: 20px;
}

.ResultBox::-webkit-scrollbar{
  display:none;
}

.SearchResult{
  padding-left: 3%;
  font-size: 1.3em;
  border-bottom: black 0.8px solid;
}

.Btn, .Btn:hover{
  background-color: #3D5398;
  color: #FFFFFF;
  border: #3D5398 !important;
  width: 45%;
  height: 40px;
  margin: 0 auto;
  margin-bottom: 5%;
}