.Dropdown {
  width: 80%;
  margin-left: 10%;
}

.DropdownContent {
  background-color: white !important;
  border: 3px solid #3d5398 !important;
  color: black !important;
  margin: 3% 0;
  display: flex !important;
  text-align: center !important;
  justify-content: space-between !important;
  border-radius: 8px !important;
  font-size: 0.875rem !important;
}

.DropdownContent::after {
  margin-top: 3%;
  color: #3d5398;
}
