.item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.15rem;
  font-weight: 700;
  /* border: 1px solid black; */
  height: 100px;
  transition: all 0.5s;
}
.selected {
  background-color: violet;
}

.item-assign,
.item-num {
  min-width: 1.25rem;
  max-width: 1.25rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}

.item-text {
  height: 100%;
  padding: 0 0.25rem;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  border: 1px solid black;
  overflow-y: scroll;
}
