.file-input {
  font-size: 1.2rem;
}

.button {
  font-size: 1.2rem;
  border: 2px solid black;
  background-color: transparent;
  padding: 0.3rem 0.6rem;
  border-radius: 0.5rem;
  transition: all ease 0.5s;
}

.button:hover {
  background-color: aqua;
}
