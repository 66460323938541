.Container {
    color: black;
    text-align: center;
    padding: 3%;
}

.User {
    font-size: 1.7em;
    margin-top: 1%;
    color: black;
    text-align: left;
}

.UserRole{
  font-size: 0.7em;
  border: 1px solid #4569F0;
  border-radius: 15px;
  padding: 0.5%;
}

.ContentWrap{
  padding: 2%;
}

.Content{
  font-size: 1.5em;
  font-weight: bolder;
  margin-top: 1.5%;
  margin-bottom: 2.5%;
  width: 100%;
  text-align: left;
}

.ExamBtn{
  outline: none;
  background-color: #4569F0;
  cursor: pointer;
  font-size: 1.4vw;
  max-height: 45px;
  min-height: 40px;
  display: inline-block;
  padding: 0.5% 2% 0.5% 2%;
  border-radius: 50px;
  color: white;
  border: #4569F0;
  margin-left: 1.2%;
}

.BatchCreateBtn{
  width: 100%;
  outline: none;
  background-color: #4569F0;
  cursor: pointer;
  font-size: 1vw;
  max-height: 45px;
  min-height: 40px;
  display: inline-block;
  padding: 0.5% 2% 0.5% 2%;
  border-radius: 50px;
  color: white;
  border: #4569F0;
}

.ModalText{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: smaller;
    line-height: 5px;
    margin-top: 7%;
}