.Container{
    max-width: 50vh;
    padding: 3%;
}

.Title{
    font-weight: bolder;
    font-size: 1.3em;
    margin-top: 3%;
}

.Content{
    text-align: center;
    font-size: 1.2em;
    margin-top: 30%;
}

.LoginBtn{
    text-align: center;
    margin-top: 7%;
}