.btn {
  font-size: 1.25rem;
  border: none;
  background-color: transparent;
  font-weight: 500;
}
.selected {
  color: blue;
  font-weight: 700;
}
