.Container{
  height: 40vh;
  overflow-y: scroll;
}

.InputWrap{
  padding: 1%;
}
.AnswerNum{
  color: #000000;
  min-width: 5%;
  max-width: 5%;
  margin: 0 2%;
  font-size: 1.3em;
  font-weight: bolder;
  display: inline-block;
}

.InputBoxWrap{
  position: relative;

}

.InputBox{
  height: 35px;
  width: 85%;
  margin-right: 3%;
  border: 2px solid #636363;
  margin-bottom: 2%;
  display: inline-block;
  border-radius: 8px;
}

.RemoveBtn, .RemoveBtn:hover{
  background-color: white !important;
  color: #636363 !important;
  border: none !important;
  font-size: 1.2em !important;
  font-weight: bolder !important;
  padding: 0 !important ;
}


.AddAnswerBtn, .AddAnswerBtn:hover{
  border: solid #3D5398  !important;
  border-radius: 30px !important;
  background-color: #3D5398  !important;
  color: white !important;
  font-size: 0.7em !important;
}

.SubmitBtn, .SubmitBtn:hover{
  border: 1px solid #3D5398  !important;
  background-color: #3D5398 !important;
  color: white !important;
  font-size: 1em !important;
  margin-top: 3%;
  font-weight: bolder;
  width: 25%;
  align-items: center;
}


.SubmitBtnOff, .SubmitBtnOff:hover{
  border: 1px solid #D9D9D9  !important;
  background-color: #D9D9D9 !important;
  color: white !important;
  font-size: 1em !important;
  margin-top: 3%;
  font-weight: bolder;
  width: 25%;
  align-items: center;
}