.HeaderWrap {
  padding: 1%;
  position: sticky !important;
  z-index: 999 !important;
  top: 0 !important;
  border-bottom: 2px solid;
  justify-content: space-between;
  background-color: #3d5398 !important;
  color: #3d5398;
}

.HeaderWrapUser {
  padding: 1%;
  position: sticky !important;
  z-index: 999 !important;
  top: 0 !important;
  border-bottom: 2px solid;
  justify-content: space-between;
  background-color: #3d5398 !important;
  color: #3d5398;
  min-width: 100vw;
}

.ModalText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: smaller;
  line-height: 5px;
  margin-top: 7%;
}

.nav-wrap {
  justify-content: space-between;
  width: 70%;
  text-align: center;
}

.List,
.List:hover {
  font-size: 1.25rem !important;
  color: black !important;
  vertical-align: bottom;
  cursor: pointer;
  width: 100%;
  margin-top: 1%;
  vertical-align: middle;
  color: white !important;
}

.ListTitle,
.ListTitle:hover {
  font-weight: bold !important;
  font-size: 1.25rem !important;
  cursor: pointer;
  color: white !important;
}

.SideList,
.SideList:hover {
  color: black !important;
  line-height: 1em !important;
  margin-left: 10%;
  min-width: 200px;
}

.Close {
  margin-left: 80%;
}
