.sidebar {
  width: 28vw;
  /* height: 100%; */
  overflow-y: scroll;
  margin: 0;
  padding: 0.5rem ;
  flex: 1 1 auto;
}

.header {
  font-size: 1.5rem;
  font-weight: 900;
  text-align: center;
  border: 2px solid black;
  border-bottom: 1px solid black;
}