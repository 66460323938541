.btn-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.btn {
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
