.real-time-container {
  width: 100%;
  display: flex;
  margin-top: 1rem;
}
.canvas-container {
  background-color: #aaaaaa;
  width: 80%;
  height: 40vh;
}
