.header {
  font-weight: 700;
  text-align: left;
  margin-top: 5%;
  margin-right: 4%;
  min-height: 45px;
}

.resultBtn,
.resultBtn:hover {
  border-radius: 67px !important;
  background-color: #d9d9d9 !important;
  border: none !important;
  color: black !important;
  font-size: 0.625rem !important;
  float: right !important;
  height: 25px !important;
}
