*{
  font-family: 'Noto Sans KR', sans-serif;
}
.Container {
  color: black;
  /* text-align: center; */
  padding: 2%;
  font-size: 1em;
  max-width: 70vh;
  height: 90vh;
}


.User {
  font-size: 1.5em;
  margin-top: 1%;
  margin-bottom: 1%;
  color: black;
  text-align: left;
}

.Title {
  display: inline-block;
  border: 2px solid;
  padding: 1% 2% 1% 2%;
  font-size: 25px;
  width: 300px;
  text-align: center;
}

.BtnWrap {
  border: 4px solid #d9d9d9;
  display: inline-block;
  width: 22%;
  height: 280px;
  border-radius: 10px;
  padding: 1%;
  vertical-align: middle;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}

.BtnWrapOn {
  border: 4px solid #4569f0;
  display: inline-block;
  width: 22%;
  height: 280px;
  border-radius: 10px;
  padding: 1%;
  vertical-align: middle;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}

.CheckIcon {
  position: absolute;
  right: 5%;
}

.ResultBtnOn {
  display: inline-block;
  border: 0;
  text-align: center;
  font-size: 15px;
  width: 100%;
  margin: 0 auto;
  background-color: #ececec;
  color: #8b8b8b;
  line-height: 10px;
  padding: 2%;
  background-color: white;
  color: #4569f0;
  font-weight: bold;
  cursor: pointer;
}

.ResultBtn {
  display: inline-block;
  border: 0;
  text-align: center;
  font-size: 15px;
  width: 100%;
  margin: 0 auto;
  background-color: #ececec;
  color: #8b8b8b;
  line-height: 10px;
  padding: 2%;
  background-color: white;
  color: #d9d9d9;
  font-weight: bold;
  cursor: pointer;
}

.ModalText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: smaller;
  line-height: 5px;
  margin-top: 7%;
}

.NextBtn {
  background-color: #4569f0;
  color: white;
  width: 20%;
  height: 45px;
  display: inline-block;
  margin-top: 2%;
  border-radius: 4px;
  cursor: pointer;
  border: #4569f0;
  font-size: larger;
  font-weight: bolder;
}

.ContentWrap {
  /* 이부분을 display flex로! */
  padding: 2%;
}

.SlideWrap {
  /* position: relative; */
  display: flex;
}

.slider-item {
  max-height: 250px;
}
.Img {
  width: 65%;
  max-height: 200px;
  min-height: 20px;
  margin: 0 auto;
  cursor: pointer;
}

.LockWrap {
  min-width: 50%;
  max-width: 75%;
  margin: 0 auto;
}

.Round:first-child {
  margin-top: 5.5%;
}
.Result {
  margin-top: 15%;
  color: #767676;
}

.TestTitle {
  margin-top: 8%;
  font-weight: bold;
}

.UserRole {
  font-size: 0.6em;
  border: 1px solid #4569f0;
  border-radius: 10px;
  padding: 1%;
}

.Round {
  position: absolute;
  color: #4569f0;
  font-size: 1.5em;
  font-weight: bolder;
  width: 100%;
  left: -30%;
}

@media (max-width: 1000px) {
  .Round {
    position: absolute;
    color: #4569f0;
    font-size: 1.3em;
    font-weight: bolder;
    width: 100%;
    left: -22%;
  }
  .Round:first-child {
    margin-top: 6.5%;
  }
}
