.CardWrap {
  float: left;
  text-align: center;
  font-size: 1.2em;
}

.QuestionNum {
  margin-top: 5% !important;
  margin-bottom: 6% !important;
  font-weight: bolder !important;
  font-size: 0.75rem !important;
}
.QuestionName {
  margin-top: 5% !important;
  margin-bottom: 6% !important;
  font-weight: bolder !important;
  font-size: 0.875rem !important;
}

.SubQuestionTitle {
  font-size: 0.75rem;
}

.BtnOn {
  color: #3d5398 !important;
  min-height: 40px;
  max-height: 40px;
  border-top: 2px solid #d9d9d9 !important;
  border-left: 2px solid #d9d9d9 !important;
  border-right: 2px solid #d9d9d9 !important;
  border-radius: 15px 15px 0 0 !important;
}

.BtnOff {
  color: black !important;
  background-color: #d9d9d9 !important;
  min-height: 40px;
  max-height: 40px;
  border: 2px solid #d9d9d9 !important;
  border-radius: 15px 15px 0 0 !important;
}

.RankBox {
  border: solid 1px #3d5398;
  border-radius: 8px;
  background-color: #3d5398;
  color: white;
  min-height: 13vh;
  margin: 6% 0;
  position: relative;
}

.RankBoxContent {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  font-size: 0.75rem;
}

.RankBox1 {
  border: solid 1px #3d5398;
  border-radius: 8px;
  background-color: #3d5398;
  color: white;
  min-height: 160px;
  margin: 40% 0;
  position: relative;
}

.RankBoxContent1 {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;
  font-size: 1em;
}

.RefreshIcon {
  width: 100%;
  height: 15px;
}

.StartQuestionBtn {
  margin-top: 7% !important;
  background-color: #3d5398 !important;
  border-radius: 8px !important;
  border: #3d5398 !important;
  width: 100%;
  font-size: 0.75rem;
}

.SolveQuestionBtn {
  border-radius: 39px !important;
  background-color: white !important;
  color: black !important;
  width: 80%;
  border: none !important;
  margin-top: 3%;
  font-size: 0.75rem;
}
