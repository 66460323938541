.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.welcome-text {
  color: #3d5398;
  font-size: 1rem;
  font-weight: bolder;
  margin-top: 2%;
  padding: 2.5%;
  position: relative;
  width: 75%;
  z-index: 100;
  text-align: center;
}

.welcomeTextPc {
  color: #3d5398;
  font-size: 1rem;
  font-weight: bolder;
  padding: 2%;
  position: relative;
  width: 75%;
  z-index: 100;
  text-align: center;
}
