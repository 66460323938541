.btn-container {
  display: flex;
  justify-content: space-between;
}
.btn-container button {
  border: 1px solid black;
  margin-left: 0.25rem;
  background-color: white;
  border-radius: 0.25rem;
  transition: 0.5s all;
}

.btn-container button:hover {
  background-color: lightgray;
}

.btn-container button.active {
  background-color: lightgray;
}
