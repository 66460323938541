.Modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
}

.CloseX {
  outline: none;
  background-color: #f5f5f5;
  cursor: pointer;
  border: 0;
  font-size: 2em;
  margin-right: 5%;
  height: 3px;
}

.CloseBtn {
  width: 100%;
  outline: none;
  background-color: #4569f0;
  cursor: pointer;
  font-size: 1em;
  width: 120px;
  max-width: 150px;
  height: 37px;
  display: inline-block;
  padding: 5px 15px 5px 15px;
  line-height: 20px;
  border-radius: 8px;
  color: white;
  border: #4569f0;
  margin-top: 2%;
}

.CloseBtn_1 {
  width: 100%;
  outline: none;
  background-color: #f5f5f5;
  cursor: pointer;
  font-size: 15px;
  width: 150px;
  height: 40px;
  display: inline-block;
  padding: 5px 15px 5px 15px;
  line-height: 20px;
  border-radius: 8px;
  border: 2px solid grey;
}

.Container {
  width: 100%;
  max-width: 350px;
  height: 60%;
  max-height: 220px;
  margin: 0 auto;
  border-radius: 7px;
  background-color: #f5f5f5;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  overflow: auto;
}

.ContainerSmall {
  width: 100%;
  max-width: 350px;
  height: 25%;
  max-height: 260px;
  margin: 0 auto;
  border-radius: 7px;
  background-color: #f5f5f5;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  overflow: auto;
}

.Content {
  background-color: #f5f5f5;
  text-align: center;
  margin-bottom: 8%;
  font-size: 1.1em;
  color: black;
  line-height: 10px;
  margin-top: 3%;
}

.OpenModal {
  display: flex;
  align-items: center;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-bg-show 0.3s;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
}
@keyframes modal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
