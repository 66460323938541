.Container{
    padding: 3%;
}

.Title{
    font-weight: bolder;
    font-size: 1.4vw;
}

.TaskWrap{
    height: 100%;
    margin: 0 auto;
    border: 1px solid;
}

.Researcher{
    display: flex;
    align-items: flex-start;

}

.ResearcherBox{
    flex: 1;
    text-align: left;

    min-height: 30px;
    border: 1px solid;
    padding: 1%;
    margin-left: 0;
}



.TaskBoxOff{
    background-color: #D9D9D9;
}


.Task{
    display: flex;
    margin-top: 2%;
}

.Dropdown,
.Dropdown:hover,
.Dropdown:active {
  border: none !important;
  font-size: 1.5vw !important;
}

.Test{
    border: 1px solid;
    margin-top: 2%;
    margin-bottom: 2%;
    flex: 1;
    text-align: left;
    min-height: 200px;
}

.DivBox{
    max-width: 200px;
    min-width: 200px;
    text-align: center;
}

.ChoiceTask{
    display: flex;
    align-items: flex-start;
}

.DueDate{
    border: 1px solid;
    margin-bottom: 2%;
    flex: 1;
    text-align: left;
    min-height: 50px;
    padding: 1%;
}

.Calender{
font-size: 1.2em;
padding: 0.5%;
}

.FinishBtn{

}

