.item {
  display: flex;
  justify-content: space-between;
}

.selected {
  border: 1px solid red;
}

.number {
  flex-basis: 1.5rem;
  flex-shrink: 0;
  text-align: center;
}

.state {
  min-width: 1rem;
  flex-basis: 1rem;
  flex-shrink: 0;
}

.text {
  flex-grow: 1;
}
