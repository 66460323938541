.edit-theory-container {
  display: flex;
  justify-content: space-between;
  height: 100%;
  flex: 1 1 auto;
  margin: 0;
  max-height: 95vh;
}

/* .edit-theory {
  flex: 1 1 auto;
}

*/
