.Container{
    padding: 3%;
}

.Title{
    font-weight: bolder;
    font-size: 1.4vw;
}

.TaskWrap{
    height: 100%;
    margin: 0 auto;
    margin-top: 3%;
}

.Researcher{
    display: flex;
    align-items: flex-start;

}

.ResearcherBox{
    flex: 1;
    text-align: left;
    min-height: 30px;
    padding: 1%;
    margin-left: 0;
}


.Task{
    display: flex;
    margin-top: 2%;
}

.Dropdown,
.Dropdown:hover,
.Dropdown:active {
  border: none !important;
  font-size: 1.5vw !important;
}

.Test{
    margin-top: 2%;
    margin-bottom: 2%;
    flex: 1;
    text-align:left;
}

.DivBox{
    margin-left: 2%;
    max-width: 180px;
    min-width: 200px;
    text-align: left;
    font-size: 1.3em;
}

.ChoiceTask{
    display: flex;
    align-items: flex-start;
}


.AddBtn{
    margin-top: 4%;
}

.PlusBtn{
    margin-right: 2%;
    border: none !important;
    background-color: #F2F2F2 !important;
}
