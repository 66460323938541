.canvas-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}
.canvas-container,
.canvas {
  background-color: #aaaaaa;
}

.edit-theory {
  width: 70vw;
  height: 90vh;
}
