.cell{
    text-decoration: none;
    color: black;
}

.Head{
    height: 40px;
    font-size: larger;
    font-weight: bolder;
    background-color: #F1F1F1;
}

.Content{
    height:50px;
    font-size: larger;
}

.ContentNone{
    display: none;
}

.Header{
    height: 60px;
    background-color: aliceblue;
}

.HeaderNone{
    display: none;
}

.Content:hover{
    cursor: pointer;
}

.Table{
    width: 100%;
    text-align: center;
    margin-top: 4%;
}

